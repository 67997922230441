body {
  margin: 0;
}
.app {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
}
.app .workspace {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.app .workspace .react-monaco-editor-container {
  flex: 1;
}
.app .preview {
  flex: 1;
  height: 100%;
}
.app .preview iframe {
  border: 0;
  height: 100%;
  width: 100%;
}
